import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import styles from './footer.module.scss';



const Footer = () => {
	const logo = `<use xlink:href="#glidewell-logo" />`;
	/**
	 * Add sticky class for footer G when scrolling past top of footer navs section.
	 */
	/*   window.addEventListener('scroll', () => {
		const footerNavs: HTMLElement | null = document.querySelector(`.${styles.footerNavs}`);
		if (footerNavs) {
		  const stickyClass = styles.gFixed;
		  if (window.pageYOffset >= footerNavs.offsetTop - 83) {
			footerNavs.classList.add(stickyClass);
		  } else {
			footerNavs.classList.remove(stickyClass);
		  }
		}
	  }); */

	const queryResult = useStaticQuery(graphql`
    query linkQuery {
		allContentfulFooter(filter: {name: {eq: "Main Footer"}}) {
		  nodes {
			name
			copyright {
			  copyright
			}
			address {
			  address
			}
			socialLink {
			  id
			  imageUrl {
				imageUrl
			  }
			  altText
			  outboundUrl {
				outboundUrl
			  }
			}
			simpleLink {
			  id
			  link
			  title
			}
		  }
		}
	  }
	   
  `);

	var footerData = queryResult.allContentfulFooter.nodes[0];
	footerData.copyright.copyright = footerData.copyright.copyright
		? footerData.copyright.copyright.replace('{year}', new Date().getFullYear().toString())
		: '';

	const socialLinkList = footerData.socialLink.map((socialLink) => (
		<li key={socialLink.id}>
			<a
				className="link-plain"
				href={socialLink.outboundUrl.outboundUrl}
				aria-label={socialLink.altText}
				target="_blank"
				rel="noopener noreferrer"
			>
				<img className={`${styles.social}`} src={socialLink.imageUrl.imageUrl} alt={socialLink.altText} />
			</a></li>
	));

	const simpleLinkList = footerData.simpleLink.map((simpleLink) => (
		<p key={simpleLink.id} className={`${styles.footersimplelink}`}>
			&nbsp;&nbsp;|&nbsp;&nbsp;
			<a
				href={simpleLink.link}
				aria-label={simpleLink.altText}
			>
				{simpleLink.title}
			</a>

		</p>
	));


	return (
		<footer className={`${styles.sitefooter}`}>

			<div className={`container-fluid ${styles.sitefooterContainterLight}`}>
				<div className="container">
					<div className="row">
						<div className="col-md-8 col-sm-6 col-xs-12">
							<a
								className="link-plain"
								href="/">

								<h1>HSCT</h1>
							</a>
						</div>

						<div className="col-md-4 col-sm-6 col-xs-12">
							<p className={`${styles.copyrighttext}`}>{footerData.copyright.copyright}</p>
						</div>
					</div>
				</div>
			</div>
			<div className={`container-fluid ${styles.sitefooterContainterDark}`}>
				<div className="container">
					<div className="row">
						<div className="col-md-8 col-sm-6 col-xs-12">
							<p className={`${styles.copyrighttext} ${styles.footersimplelink}`}>{footerData.address.address}</p>
							{simpleLinkList}
						</div>

						<div className="col-md-4 col-sm-6 col-xs-12">
							<ul className={`${styles.socialicons}`}>
								{socialLinkList}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</footer>


	);
};

export default Footer;
