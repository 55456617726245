import { useStaticQuery } from "gatsby";
import React, { useContext } from "react";
import ReactHtmlParser from "react-html-parser";

// const getPages = graphql`
// 	query {
// 		pages: allContentfulCopyWithBulletPoints {
// 			edges {
// 				node {
// 					copy {
// 						childMarkdownRemark {
// 							html
// 						}
// 					}
// 					copy2 {
// 						childMarkdownRemark {
// 							html
// 						}
// 					}
// 					title
// 					id
// 					sectionTitle
// 					routeName
// 					bulletPoints {
// 						childMarkdownRemark {
// 							html
// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
// `;
const getPages = graphql`
	query {
		pages: allContentfulPageSetup {
			edges {
				node {
					bulletPoints {
						id
						childMarkdownRemark {
							html
						}
					}
					copy {
						childMarkdownRemark {
							html
						}
					}
					copy2 {
						childMarkdownRemark {
							html
						}
					}
					id
					routeName
					sectionTitle
					title
				}
			}
		}
	}
`;

export const Pages = () => {
	const {
		pages: { edges },
	} = useStaticQuery(getPages);
	// console.log(edges);

	const menuItems = edges.reduce((acc, curr) => {
		const {
			node: { title, routeName },
		} = curr;
		acc.push({ name: routeName ? routeName : "", label: title });

		return acc;
	}, []);

	const homePage = edges.filter(
		({ node: { id } }) => id === "a54d0213-de17-5841-9283-a96899043896"
	);

	const aboutUs = edges.filter(({ node: { title } }) => title === "About Us");

	const prospect = edges.filter(
		({ node: { id } }) => id === "c2a8e38f-9bd7-5f7f-a030-ec0b4ca8f5b8"
	);

	const whitepaper = edges.filter(
		({ node: { id } }) => id === "ddae7be1-7fc9-58ae-b4f5-16ce66383ec0"
	);

	const contact = edges.filter(
		({ node: { id } }) => id === "51831be1-84aa-5132-b054-f63cc61490ee"
	);

	return { menuItems, homePage, aboutUs, prospect, whitepaper, contact };
};
